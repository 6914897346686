import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormatDate, Translate } from "../../../utils/lang/translate";
import ContainerPage from "../_Common/ContainerPage";
import { ExchangeListItem, RequestStatus } from "../../../types";
import { alertActions, exchangeActions } from "../../../actions";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Link, RouterChildContext, useHistory, useParams } from "react-router-dom";
import IconButton from "../_Common/IconButton";
import { CallSvg, DelivredSvg, ExpressSvg, LocationSvg, ManagentSvg, PersonSvg, PriceSvg, PrintSvg, ShipedSvg, deliveredProductsSvg, returnedProductsSvg } from "../_Common/IconSvg";
import Loader from "../../_Common/Loader";
import Table from "../_Common/Table";
import ListDetailIcon from "../_Common/ListDetailIcon";
import Cookies from "js-cookie";

const lang = Cookies.get("lang"); 

interface ExchangeDetailsPageProps {
  GettingExchanges: RequestStatus,
  GetExchanges: (filter?: string) => void,
  dataExchanges: { 
    ressuc: {
      count: number,
      list: ExchangeListItem [],
    }, 
    filter: string,
  },
  dataProfile: any,
  SendAlert: (code: string, text: string, action: string) => void,
  GenerateExchangesBordureauStarter: (exchanges_ids: string[], all_created: boolean) => void,
  dtStoreinfo: any,
}

const ExchangeDetailsPage = ({
  GetExchanges,
  dataExchanges,
  GettingExchanges,
  dataProfile,
  SendAlert,
  GenerateExchangesBordureauStarter,
  dtStoreinfo
}: ExchangeDetailsPageProps) => {

  const history = useHistory<RouterChildContext['router']['history']>();

  const { idExchange } = useParams<{ idExchange: string }>();

  useEffect(() => {
    //something
  }, []);

  useEffect(() => {
    if(GettingExchanges === "3"){
      SendAlert("50", Translate("exchanges", "failedtofetchexchanges"), "");
    }
  }, [GettingExchanges]);

  return (
    <>
    {
      GettingExchanges === "1"
        ?
          <Loader />
        :
          <ContainerPage
            page_title={
              <div className="StDrc">
                <Link className="StOpcVal" to={"/exchanges" + (dataExchanges?.filter ?? "")}>
                  {Translate("exchanges", "exchange")}
                </Link>
                <div className="Inlflx StWdDst">
                  {Translate("orders", "flech")}
                </div>
                <div className="Inlflx">
                  {Translate("exchanges", "exchangedetail")}
                </div>
                <div className="Inlflx StWdDst">#</div>
                <div className="Inlflx">{idExchange}</div>
              </div>
            }
            data_top={[
              <IconButton 
                clickHandler={() => GenerateExchangesBordureauStarter([], false)}
                icon={PrintSvg}
                label={Translate("exchanges", "printslip")} 
              />
            ]}
          >
            {
              (dataProfile.is_store || dataProfile.view_order)
              &&
              <>
                {
                  //(GettingExchanges && GettingExchanges !== "3")
                  true
                  ?
                    <>
                      <div className="FlWd InFlx responsFlx flxDrc">
                        <div className="Hlwd">
                          <Table
                            ntdir={true}
                            thead_elem={[Translate("exchanges", "exchangedetails")]}
                            tbody_elem={[
                              [
                                <ListDetailIcon
                                  icon={returnedProductsSvg}
                                  title={Translate("exchanges", "returnedproducts")}
                                  details={["produit1, produit2"]}
                                />,
                              ],
                              [
                                <ListDetailIcon
                                  icon={deliveredProductsSvg}
                                  title={Translate("exchanges", "orderproducts")}
                                  details={["produit1, produit2"]}
                                />,
                              ],
                              [
                                <ListDetailIcon
                                  icon={PriceSvg}
                                  title={Translate("orders", "totalprice")}
                                  details={[
                                    lang === "ar"
                                    ?
                                      (500).toString() + " " + dtStoreinfo.country.ar_currency
                                    :
                                      (500).toString() + " " + dtStoreinfo.country.lt_currency
                                  ]}
                                />
                              ],
                              [
                                <div className="FlWd InFlx responsFlx flxDrc">
                                  <div className="Hlwd">
                                    <ListDetailIcon
                                      icon={PersonSvg}
                                      title={Translate("orders", "customername")}
                                      details={["zakaria"]}
                                    />
                                  </div>
                                  <div className="Hlwd Mrg2">
                                    <ListDetailIcon
                                      icon={CallSvg}
                                      title={Translate("orders", "customerphonenumber")}
                                      details={["0794449080"]}
                                    />
                                  </div>
                                </div>,
                              ],
                              [
                                <div className="FlWd InFlx responsFlx flxDrc">
                                  <div className="Hlwd">
                                    <ListDetailIcon
                                      icon={LocationSvg}
                                      title={Translate("orders", "adresse")}
                                      details={["Cité 248 logement, BT2-numéro 05", "lalala"]}
                                    />
                                  </div>
                                  <div className="Hlwd Mrg2">
                                    <ListDetailIcon
                                      icon={LocationSvg}
                                      title={Translate("orders", "district")}
                                      details={[
                                        "Dar-el-beida" +
                                          " ( " +
                                          "Alger" +
                                          " ) ",
                                      ]}
                                      commune
                                    />
                                  </div>
                                </div>,
                              ],
                              [
                                <ListDetailIcon
                                  icon={ExpressSvg}
                                  title={Translate("orders", "expressdelivery")}
                                  details={["yes"]}
                                />,
                              ],
                            ]}
                          />
                        </div>
                        <div className="Hlwd Mrg2">
                          <Table
                            ntdir={true}
                            thead_elem={[Translate("orders", "delivrystatus")]}
                            tbody_elem={[
                              [
                                <ListDetailIcon
                                  icon={DelivredSvg}
                                  Styleicon="ElmSvgDl"
                                  title={Translate("statusorder", "41")}
                                  details={[
                                    FormatDate(new Date()),
                                    <span
                                      className={"StAbs status_41 LeftSet StTpval2 WdSmP HidLstELm_0"}
                                      style={{ height: "100%" }}
                                    ></span>,
                                  ]}
                                />
                              ]
                            ]}
                          />
                          <div className="StMrtp">
                            <Table
                              ntdir={true}
                              thead_elem={[Translate("orders", "deliveryagent")]}
                              tbody_elem={[
                                [
                                  <div className="FlWd InFlx responsFlx flxDrc">
                                    <div className="Hlwd">
                                      <ListDetailIcon
                                        icon={ManagentSvg}
                                        title={Translate("auths", "fullname")}
                                        details={[
                                          "ANSER Zakaria"
                                        ]}
                                      />
                                    </div>
                                    <div className="Hlwd Mrg2">
                                      <ListDetailIcon
                                        icon={CallSvg}
                                        title={Translate("auths", "phone")}
                                        details={["0794449080"]}
                                      />
                                    </div>
                                  </div>,
                                ],
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </>   
                  :
                    <div>
                      error loading exchange details!!!
                    </div>
                }
              </>
            }

            {
              (!dataProfile.is_store || !dataProfile.view_order)
              &&
              <FeatureLoked />
            }

          </ContainerPage>
      }
    </>
  );
}
 
function mapState(state: any) {
  const { dataExchanges, GettingExchanges } = state.exchanges;
  const { dataProfile, dtStoreinfo } = state.user;
  return { dataExchanges, GettingExchanges, dataProfile, dtStoreinfo }
};

const actionCreators = {
  GetExchanges: exchangeActions.GetExchanges,
  SendAlert: alertActions.SendAlert,
  GenerateExchangesBordureauStarter: exchangeActions.GenerateExchangesBordureauStarter
}

const connctedExchangeDetailsPage = connect(mapState, actionCreators)(ExchangeDetailsPage);
export { connctedExchangeDetailsPage as ExchangeDetailsPage };