import { shopConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
import {
  Bundle,
  DataShop,
  DeletedShopPhoto,
  LandingPageSettings,
  PricingOption,
  PricingOptionWithoutId,
  ProductShop,
  ProductShopPhoto,
  ProductShopWithVariants,
  UpdateDataShopSettings,
  UploadDataShopLogoSettings,
} from "../types";
import { store } from "../utils/Store";
import {
  createFormDataFromArray,
  createObjectFromKeys,
  filterObjectByChangingValues,
  getBundleFromResponse,
  getLandingPageSettingsFromResponse,
  getProductFromResponse,
  getSettingsFromResponse,
} from "../utils/helpers";
const token = Cookies.get("auth");

export const shopActions = {
  GetShopInfo,
  GetShopInfoWithPricing,
  GetShopInfoWithPricingDetails,
  GetLandingPageInfo,
  UpdateDataShop,
  UpdateLandingPageSettings,
  UploadDataShopLogo,
  CreateProductShop,
  CreateProductShopWithVariants,
  ModifyProductShop,
  ModifyProductShopWithVariants,
  DeleteProductShop,
  UploadPhotoProductShop,
  DeletePhotoProductShop,
  CreateBundle,
  ModifyBundle,
  DeleteBundle,
  CreatePricingOption,
  UpdatePricingOption,
  DeletePricingOption,
};

function GetShopInfo() {
  return function (dispatch: any) {
    dispatch(FunctionService.request(shopConstants.GET_SHOP_REQUEST));
    RequestService.GetRequest("shop/settings/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            getSettingsFromResponse(res.data),
            shopConstants.GET_SHOP_SUCCESS
          )
        );
      })
      .catch((error) => {
        if (
          error?.response?.data?.status_code &&
          error.response.data.status_code.toString() === "404"
        ) {
          store.dispatch(CreateDataShop());
        } else {
          dispatch(
            FunctionService.failure(
              error?.response?.data?.detail,
              shopConstants.GET_SHOP_FAILURE
            )
          );
        }
      });
  };
}

function GetShopInfoWithPricing() {
  return function (dispatch: any) {
    let dataShop: DataShop;
    dispatch(FunctionService.request(shopConstants.GET_SHOP_REQUEST));
    RequestService.GetRequest("shop/settings/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dataShop = getSettingsFromResponse(res.data);
        return RequestService.GetRequest("shop/pricings/", {
          Authorization: "Token " + token,
        });
      })
      .then((res2) => {
        const pricingOptions = res2.data.results;
        dispatch(
          FunctionService.succes(
            { ...dataShop, pricingOptions },
            shopConstants.GET_SHOP_SUCCESS
          )
        );
      })
      .catch((error) => {
        if (
          error?.response?.data?.status_code &&
          error.response.data.status_code.toString() === "404"
        ) {
          store.dispatch(CreateDataShop());
        } else {
          dispatch(
            FunctionService.failure(
              error?.response?.data?.detail,
              shopConstants.GET_SHOP_FAILURE
            )
          );
        }
      });
  };
}

function GetShopInfoWithPricingDetails() {
  return function (dispatch: any) {
    let dataShop: DataShop;
    dispatch(FunctionService.request(shopConstants.GET_SHOP_REQUEST));
    RequestService.GetRequest("shop/settings/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dataShop = getSettingsFromResponse(res.data);
        return RequestService.GetRequest("shop/pricings/", {
          Authorization: "Token " + token,
        });
      })
      .then((res2) => {
        const pricingOptionsWithoutDetails: PricingOption[] = res2.data.results;
        return Promise.all(
          pricingOptionsWithoutDetails.map((pricingOption) =>
            RequestService.GetRequest(`shop/pricings/${pricingOption.id}`, {
              Authorization: "Token " + token,
            })
          )
        );
      })
      .then((res3) => {
        const pricingOptions: PricingOption[] = res3.map(
          (result) => result.data
        );
        dispatch(
          FunctionService.succes(
            { ...dataShop, pricingOptions },
            shopConstants.GET_SHOP_SUCCESS
          )
        );
      })
      .catch((error) => {
        if (
          error?.response?.data?.status_code &&
          error.response.data.status_code.toString() === "404"
        ) {
          store.dispatch(CreateDataShop());
        } else {
          dispatch(
            FunctionService.failure(
              error?.response?.data?.detail,
              shopConstants.GET_SHOP_FAILURE
            )
          );
        }
      });
  };
}

function CreateDataShop() {
  return function (dispatch: any) {
    RequestService.PostRequest(
      "shop/settings/",
      {},
      {
        Authorization: "Token " + token,
      }
    )
      .then((res) => {
        FunctionService.succes(
          getSettingsFromResponse(res.data),
          shopConstants.GET_SHOP_SUCCESS
        );
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.GET_SHOP_FAILURE
          )
        );
      });
  };
}

function GetLandingPageInfo() {
  return function (dispatch: any) {
    let dataShop: DataShop;
    dispatch(FunctionService.request(shopConstants.GET_SHOP_REQUEST));
    return Promise.all([
      RequestService.GetRequest("shop/settings/", {
        Authorization: "Token " + token,
      }),
      RequestService.GetRequest("shop/settings/landing-page-settings", {
        Authorization: "Token " + token,
      }),
    ])
      .then((res) => {
        dataShop = getSettingsFromResponse({ ...res[0].data });
        const landingPageSettings = getLandingPageSettingsFromResponse(
          res[1].data.results[0]
        );
        dataShop = { ...dataShop, landingPageSettings };
        dispatch(
          FunctionService.succes(dataShop, shopConstants.GET_SHOP_SUCCESS)
        );
      })
      .catch((error) => {
        if (
          error?.response?.data?.status_code &&
          error.response.data.status_code.toString() === "404"
        ) {
          store.dispatch(CreateDataShop());
        } else {
          dispatch(
            FunctionService.failure(
              error?.response?.data?.detail,
              shopConstants.GET_SHOP_FAILURE
            )
          );
        }
      });
  };
}

function UpdateDataShop(
  data: DataShop,
  settings: UpdateDataShopSettings,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    const ChangedSettings = filterObjectByChangingValues(data, settings);

    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));

    RequestService.PatchRequest("shop/settings/", ChangedSettings, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            getSettingsFromResponse(res.data),
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        updateAllLandingPages()
        if (successCallback) successCallback();
      })
      .catch((error) => {
        let errorMessage = error?.response?.data?.detail;
        const parsedErrorMessage = JSON.parse(errorMessage.replace(/'/g, `"`));
        if (Array.isArray(parsedErrorMessage) && parsedErrorMessage[0])
          errorMessage = parsedErrorMessage[0];

        dispatch(
          FunctionService.failure(
            errorMessage,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function UpdateLandingPageSettings(
  landingPageSettings: LandingPageSettings,
  dataShop: DataShop,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    const request = landingPageSettings.id
      ? RequestService.PatchRequest(
          `shop/settings/landing-page-settings/${landingPageSettings.id}/`,
          landingPageSettings,
          {
            Authorization: "Token " + token,
          }
        )
      : RequestService.PostRequest(
          "shop/settings/landing-page-settings/",
          landingPageSettings,
          {
            Authorization: "Token " + token,
          }
        );
    return request
      .then((res) => {
        const landingPageSettings = getLandingPageSettingsFromResponse(
          res.data
        );
        dataShop = { ...dataShop, landingPageSettings };
        dispatch(
          FunctionService.succes(dataShop, shopConstants.UPDATE_SHOP_SUCCESS)
        );
        updateAllLandingPages()
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function UploadDataShopLogo(
  data: DataShop,
  settings: UploadDataShopLogoSettings,
  logo: File,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    let formData = new FormData();
    formData.append("logo", logo);

    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));

    RequestService.PatchRequest("shop/settings/", formData, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const ChangedSettings = filterObjectByChangingValues(
          { ...data, logo: res.data.logo },
          settings
        );
        return RequestService.PatchRequest("shop/settings/", ChangedSettings, {
          Authorization: "Token " + token,
        });
      })
      .then((res2) => {
        dispatch(
          FunctionService.succes(
            getSettingsFromResponse(res2.data),
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function CreateProductShop(
  newProductShop: ProductShop | ProductShopWithVariants,
  newImages: File[],
  dataShop: DataShop,
  setProgress?: (percentage: number | null) => void,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    const data = createObjectFromKeys(newProductShop, [
      "title",
      "slug",
      "categories",
      "description",
      "how_to_use",
      "specifications",
      "published",
      "options",
      "product_id",
      "lang",
      "rate",
      "pricing_option",
      "template_details",
    ]);

    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.PostRequest("shop/products/", data, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const formData = createFormDataFromArray(newImages, "images");

        const onUploadProgress = (event: any) => {
          const { loaded, total } = event;
          if (setProgress) setProgress(Math.floor((loaded * 100) / total));
        };

        return RequestService.PatchRequest(
          `shop/products/${res.data.id}/`,
          formData,
          {
            Authorization: "Token " + token,
          },
          { onUploadProgress }
        );
      })
      .then((res2) => {
        if (setProgress) setProgress(null);
        const newData = {
          ...dataShop,
          products: [...dataShop.products, getProductFromResponse(res2.data)],
        };
        dispatch(
          FunctionService.succes(
            getSettingsFromResponse(newData),
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        updateAllLandingPages()
        if (successCallback) successCallback();
      })
      .catch((error) => {
        if (setProgress) setProgress(null);
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function CreateProductShopWithVariants(
  newProductShop: ProductShopWithVariants | ProductShop,
  newImages: File[],
  dataShop: DataShop,
  setProgress?: (percentage: number | null) => void,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    const data = createObjectFromKeys(newProductShop, [
      "title",
      "slug",
      "categories",
      "description",
      "how_to_use",
      "specifications",
      "published",
      "lang",
      "variants",
      "is_variant",
      "rate",
      "pricing_option",
      "display_id",
      "template_details",
    ]);

    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.PostRequest("shop/products/", data, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const formData = createFormDataFromArray(newImages, "images");

        const onUploadProgress = (event: any) => {
          const { loaded, total } = event;
          if (setProgress) setProgress(Math.floor((loaded * 100) / total));
        };

        return RequestService.PatchRequest(
          `shop/products/${res.data.id}/`,
          formData,
          {
            Authorization: "Token " + token,
          },
          { onUploadProgress }
        );
      })
      .then((res2) => {
        if (setProgress) setProgress(null);
        const newData = {
          ...dataShop,
          products: [...dataShop.products, getProductFromResponse(res2.data)],
        };
        dispatch(
          FunctionService.succes(
            getSettingsFromResponse(newData),
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        if (setProgress) setProgress(null);
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function ModifyProductShop(
  dataShop: DataShop,
  productShop: ProductShop,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    const data = createObjectFromKeys(productShop, [
      "title",
      "slug",
      "categories",
      "description",
      "how_to_use",
      "specifications",
      "published",
      "options",
      "images",
      "lang",
      "rate",
      "pricing_option",
      "template_details",
    ]);
    const previousProduct = dataShop.products.find(
      (prod) => prod.id === productShop.id
    );
    if (!previousProduct) throw Error("Product not found");
    const filteredData = filterObjectByChangingValues(previousProduct, data);

    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.PatchRequest(
      `shop/products/${productShop.id}/`,
      filteredData,
      {
        Authorization: "Token " + token,
      }
    )
      .then((res) => {
        const updatedDataShop: DataShop = {
          ...dataShop,
          products: dataShop.products.map((prod) => {
            if (prod.id !== previousProduct.id) return prod;
            return { ...prod, ...getProductFromResponse(res.data) };
          }),
        };
        dispatch(
          FunctionService.succes(
            updatedDataShop,
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        updateAllLandingPages()
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function ModifyProductShopWithVariants(
  dataShop: DataShop,
  productShop: ProductShopWithVariants,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    const data = createObjectFromKeys(productShop, [
      "title",
      "slug",
      "categories",
      "description",
      "how_to_use",
      "specifications",
      "published",
      "options",
      "images",
      "lang",
      "rate",
      "pricing_option",
      "template_details",
      "is_variant",
      "variants"
    ]);

    const previousProduct = dataShop.products.find(
      (prod) => prod.id === productShop.id
    );

    if (!previousProduct) throw Error("Product not found");
    const filteredData = filterObjectByChangingValues(previousProduct, data);

    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.PatchRequest(
      `shop/products/${productShop.id}/`,
      filteredData,
      {
        Authorization: "Token " + token,
      }
    )
      .then((res) => {
        const updatedDataShop: DataShop = {
          ...dataShop,
          products: dataShop.products.map((prod) => {
            if (prod.id !== previousProduct.id) return prod;
            return { ...prod, ...getProductFromResponse(res.data) };
          }),
        };

        dispatch(
          FunctionService.succes(
            updatedDataShop,
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function DeleteProductShop(
  id: string,
  dataShop: DataShop,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.DeleteRequest(`shop/products/${id}/`, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const updatedDataShop: DataShop = {
          ...dataShop,
          products: dataShop.products.filter((prod) => prod.id !== id),
        };
        dispatch(
          FunctionService.succes(
            updatedDataShop,
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        updateAllLandingPages()
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function UploadPhotoProductShop(
  newImage: File,
  productId: string,
  setProgress?: (percentage: number | null) => void,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    const formData = new FormData();
    formData.append("image", newImage);

    const onUploadProgress = (event: any) => {
      const { loaded, total } = event;
      if (setProgress) setProgress(Math.floor((loaded * 100) / total));
    };

    dispatch(
      FunctionService.request(shopConstants.ADD_PRODUCT_SHOP_PHOTO_REQUEST)
    );
    RequestService.PostRequest(
      `shop/product/${productId}/images/`,
      formData,
      {
        Authorization: "Token " + token,
        "Content-Type": "multipart/form-data",
      },
      { onUploadProgress }
    )
      .then((res) => {
        if (setProgress) setProgress(null);
        const response: ProductShopPhoto = {
          url: res.data.url,
          id: res.data.id,
          productId,
        };
        dispatch(
          FunctionService.succes(
            response,
            shopConstants.ADD_PRODUCT_SHOP_PHOTO_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        if (setProgress) setProgress(null);
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.ADD_PRODUCT_SHOP_PHOTO_FAILURE
          )
        );
      });
  };
}

function DeletePhotoProductShop(
  imageId: string,
  productId: string,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    dispatch(
      FunctionService.request(shopConstants.DELETE_PRODUCT_SHOP_PHOTO_REQUEST)
    );
    RequestService.DeleteRequest(`shop/product/images/${imageId}/`, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const response: DeletedShopPhoto = { imageId, productId };
        dispatch(
          FunctionService.succes(
            response,
            shopConstants.DELETE_PRODUCT_SHOP_PHOTO_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.DELETE_PRODUCT_SHOP_PHOTO_FAILURE
          )
        );
      });
  };
}

function CreateBundle(
  newBundle: Bundle,
  dataShop: DataShop,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    let data: any = createObjectFromKeys(newBundle, [
      "product_bundle",
      "price",
      "reduced_price",
      "pricing_option",
    ]);
    // change "product" object by "product.id" to help the back-end
    data = {
      ...data,
      product_bundle: data.product_bundle.map((prod: any) => {
        return { ...prod, product: prod.product.id };
      }),
    };

    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.PostRequest(`shop/bundles/`, data, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const newDataShop: DataShop = {
          ...dataShop,
          shop_bundles: [
            ...dataShop.shop_bundles,
            getBundleFromResponse(res.data),
          ],
        };
        dispatch(
          FunctionService.succes(newDataShop, shopConstants.UPDATE_SHOP_SUCCESS)
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function ModifyBundle(
  bundleToModify: Bundle,
  dataShop: DataShop,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    const data = createObjectFromKeys(bundleToModify, [
      "product_bundle",
      "price",
      "reduced_price",
      "id",
      "pricing_option",
    ]);
    const previousBundle = dataShop.shop_bundles.find(
      (bundle) => bundle.id === bundleToModify.id
    );
    if (!previousBundle) throw Error("previousBundle not found");
    let filteredData: any = filterObjectByChangingValues(previousBundle, data);
    // change "product" object by "product.id" to help the back-end
    filteredData = {
      ...filteredData,
      product_bundle: filteredData.product_bundle?.map((prod: any) => {
        return { ...prod, product: prod.product.id };
      }),
    };

    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.PatchRequest(
      `shop/bundles/${bundleToModify.id}/`,
      filteredData,
      {
        Authorization: "Token " + token,
      }
    )
      .then((res) => {
        const updatedDataShop: DataShop = {
          ...dataShop,
          shop_bundles: dataShop.shop_bundles.map((bundle) => {
            if (bundle.id !== previousBundle.id) return bundle;
            return { ...bundle, ...getBundleFromResponse(res.data) };
          }),
        };
        dispatch(
          FunctionService.succes(
            updatedDataShop,
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function DeleteBundle(
  bundleId: string,
  dataShop: DataShop,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.DeleteRequest(`shop/bundles/${bundleId}/`, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const updatedDataShop: DataShop = {
          ...dataShop,
          shop_bundles: dataShop.shop_bundles.filter(
            (bundle) => bundle.id !== bundleId
          ),
        };
        dispatch(
          FunctionService.succes(
            updatedDataShop,
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function CreatePricingOption(
  newPricingOption: PricingOptionWithoutId,
  dataShop: DataShop,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.PostRequest("shop/pricings/", newPricingOption, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const newPricingOptions: PricingOption[] = [
          ...dataShop.pricingOptions!,
          res.data,
        ];
        const updatedDataShop: DataShop = {
          ...dataShop,
          pricingOptions: newPricingOptions,
        };
        dispatch(
          FunctionService.succes(
            updatedDataShop,
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function UpdatePricingOption(
  pricingOption: PricingOption,
  dataShop: DataShop,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    const previousPricingOption = dataShop.pricingOptions!.find(
      (pr) => pr.id === pricingOption.id
    );
    if (!previousPricingOption) throw Error("Pricing option not found");
    const filteredData = filterObjectByChangingValues(
      previousPricingOption,
      pricingOption
    );
    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.PatchRequest(
      `shop/pricings/${pricingOption.id}`,
      filteredData,
      {
        Authorization: "Token " + token,
      }
    )
      .then((res) => {
        const updatedPricingOptions: PricingOption[] =
          dataShop.pricingOptions!.map((pr) => {
            if (pr.id === res.data.id) return res.data;
            return pr;
          });
        const updatedDataShop: DataShop = {
          ...dataShop,
          pricingOptions: updatedPricingOptions,
        };
        dispatch(
          FunctionService.succes(
            updatedDataShop,
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

function DeletePricingOption(
  deleteId: string,
  dataShop: DataShop,
  successCallback?: () => void
) {
  return function (dispatch: any) {
    dispatch(FunctionService.request(shopConstants.UPDATE_SHOP_REQUEST));
    RequestService.DeleteRequest(`shop/pricings/${deleteId}/`, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        const updatedDataShop: DataShop = {
          ...dataShop,
          pricingOptions: dataShop.pricingOptions!.filter(
            (pr) => pr.id !== deleteId
          ),
        };
        dispatch(
          FunctionService.succes(
            updatedDataShop,
            shopConstants.UPDATE_SHOP_SUCCESS
          )
        );
        if (successCallback) successCallback();
      })
      .catch((error) => {
        dispatch(
          FunctionService.failure(
            error?.response?.data?.detail,
            shopConstants.UPDATE_SHOP_FAILURE
          )
        );
      });
  };
}

// funciton to update all landing pages
 function updateAllLandingPages(){
  RequestService.PostRequest(
    "shop/update-landing-pages/",
    {},
    {
      Authorization: "Token " + token,
    }
  );
}