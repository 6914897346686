import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Translate } from "../../../utils/lang/translate";
import ContainerPage from "../_Common/ContainerPage";
import { ExchangeListItem, RequestStatus } from "../../../types";
import { alertActions, exchangeActions } from "../../../actions";
import { EmptyScreen } from "../_Common/EmptyScreen";
import { FeatureLoked } from "../_Common/FeatureLoked";
import InlinedElement from "../_Common/InlinedElement";
import { CheckSvg, CloseSvg, FilterSvg, NewPrintSvg, OutSvg, PrintSvg, RefreshSvg } from "../_Common/IconSvg";
import { Event } from "../_Common";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import TableLoader from "../_Common/TableLoader";
import Table from "../_Common/Table";
import { useQueryParams, NumberParam, StringParam } from "use-query-params";
import PaginationBar from "../_Common/PaginationBar";
import { RouterChildContext, useHistory } from "react-router-dom";
import StatusElementTootlip from "../_Common/StatusElementTooltip";
import GroupeBtn from "../_Common/GroupeBtn";

interface ExchangePageProps {
  GettingExchanges: RequestStatus,
  GetExchanges: (filter?: string) => void,
  dataExchanges: { 
    ressuc: {
      count: number,
      list: ExchangeListItem [],
    }, 
    filter: string,
  },
  dataProfile: any,
  SendAlert: (code: string, text: string, action: string) => void,
  GenerateExchangesBordureauStarter: (exchanges_ids: string[], all_created: boolean) => void
}

const Exchanges = ({
  GetExchanges,
  dataExchanges,
  GettingExchanges,
  dataProfile,
  SendAlert,
  GenerateExchangesBordureauStarter
}: ExchangePageProps) => {

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    display_id: StringParam,
  });

  const history = useHistory<RouterChildContext['router']['history']>();

  const FilterRef = useRef<HTMLDivElement>(null);

  const [displayId, setDisplayId] = useState<string>("");

  useEffect(() => {
    query.display_id ? 
        GetExchanges(`?display_id=${query.display_id}`)
      :
        query.page ? 
          GetExchanges(`?page=${query.page}`)
        :
          GetExchanges(""); 
  }, [query.page, query.display_id]);

  useEffect(() => {
    if(GettingExchanges === "3"){
      SendAlert("50", Translate("exchanges", "failedtofetchexchanges"), "");
    }
  }, [GettingExchanges]);

  const handleDisplayIdSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Event("EXCHANGES", "FILTER_BY_ID", "CLICK_EVENT");
    if(query.display_id) {
      setQuery(prev => ({ ...prev, display_id: undefined }));
      setDisplayId("");
    }
    else setQuery(prev => ({ ...prev, display_id: displayId }));
  }

  const ShowFilter = function() {
    Event("EXCHANGES", "CLICK_FILTER", "CLICK_EVENT");
    let clsList = document.getElementById("Filter_container");
    if (clsList && clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", handleClickOutsideFilter);
    } else {
      clsList?.classList.add("hidElem2");
      document.removeEventListener("mousedown", handleClickOutsideFilter);
    }
  }

  const handleClickOutsideFilter = function(event: MouseEvent) {
    if (
      FilterRef &&
      FilterRef.current &&
      !FilterRef.current.contains(event.target as Node)
    ) {
      ShowFilter();
    }
  }

  const handleDisplayIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayId(e.target.value);
  }

  const clearFilters = () => {
    setQuery(prev => ({ display_id: prev.display_id, page: prev.page }));
  };

  return (
    <ContainerPage
      page_title={Translate("exchanges", "exchanges")}
    >
      {
        (dataProfile.is_store || dataProfile.view_order)
        &&
        <>
          {
            (GettingExchanges && GettingExchanges !== "3")
            ?
              <div>
                <div
                  className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
                  style={{ height: "48px", padding: "7px 0", top: "-20px" }}
                >
                  {/* Filters + display_id search */}
                  <div role="filter-data" className="FlHg InFlx StwdMaxC flxDrc">
                    {/* filters */}
                    <div
                      className="FlHg ClSidTh StBrdRdS StMrtg2"
                      ref={FilterRef}
                    >
                      <div className="CrsPoi FlHg" onClick={ShowFilter}>
                        <InlinedElement
                          secnd={<div className="IcnSiz">{FilterSvg}</div>}
                        />
                      </div>
                      <div
                        id="Filter_container"
                        className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2"
                        style={{ width: "350px", maxWidth: "200px" }}
                      >
                        <div id="Sub_Container_filter">
                          <div style={{ marginTop: "8px" }}>
                            <div className="RlPs">
                              <InlinedElement
                                secnd={
                                  <strong>{Translate("orders", "filter")}</strong>
                                }
                                leftElm={
                                  <div
                                    onClick={ShowFilter}
                                    className="InFlx CrsPoi IcnSizSm"
                                  >
                                    {CloseSvg}
                                  </div>
                                }
                                style={{ width: "100%", padding: "0px" }}
                              />
                            </div>
                            {
                              Object.keys(query).filter(key => key !== "page" && key !== "display_id").length > 0
                              &&
                              <span
                                className="CrsPoi StBle DsBlc StdirLn"
                                onClick={clearFilters}
                              >
                                {Translate("orders", "resetfilter")}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* search with display id */}
                    <form onSubmit={handleDisplayIdSubmit}>
                      <div className="FlHg StBrdRdS ClSidTh">
                        <InlinedElement
                          style={{ padding: "0" }}
                          secnd={
                            <div>
                              <input
                                type="text"
                                autoComplete="off"
                                onChange={handleDisplayIdChange}
                                name="filterbyid"
                                style={{ width: "300px", maxWidth: "30vw" }}
                                className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                                value={query.display_id ?? displayId}
                                placeholder={Translate("orders", "filterbyid")}
                              />
                            </div>
                          }
                          leftElm={
                            <button
                              className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                              style={{ padding: "10px" }}
                            >
                              { query.display_id ? OutSvg : CheckSvg }
                            </button>
                          }
                        />
                      </div>
                    </form>
                  </div>
                
                  {/* Refresh button + import/export (incoming)  */}
                  <div ref={null}>
                    <div
                      className=""
                      style={{ height: "48px", marginLeft: "20px", display: "flex", alignItems: "center" }}
                    >
                      <GroupeBtnSp
                        style={{ margin: "0", zoom: "1.2", display: "flex" }}
                        data_btn={[
                          {
                            type: "BDgInpc",
                            action: () => {},
                            svgbtn: RefreshSvg,
                            tooltip: Translate("tooltips", "refresh"),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>

                {/* Selected filters display section */}
                <div className="InFlx flxDrc StmpB3 StOpcVal StFlxWrp2 FlwMax AlgnItm">

                </div> 
                {
                  GettingExchanges === "1"
                  ?
                    <TableLoader />
                  :
                    (GettingExchanges === "2" && dataExchanges.ressuc.list.length === 0)
                    ?
                      <EmptyScreen
                        titleGlb={Translate("exchanges", "noexchange")}
                        TextShow={Translate("exchanges", "noexchangeyet")}
                        PictShow={"/assets/img/exchange.png"}
                        actionBtns={
                          Object.keys(query).filter(key => key !== "page").length === 0
                          ?
                            []
                          :
                            [
                              {
                                text: Translate("orders", "resetfilter"),
                                to: "/exchanges",
                              }
                            ]
                        }
                      />
                    :
                      <div>
                        <Table
                          thead_elem={[
                            Translate("product", "id"),
                            Translate("orders", "status"),
                            Translate("orders", "district") + " / " + Translate("product", "wilaya"),
                            Translate("orders", "creationdate"),
                            Translate("product", "action"),
                          ]}
                          tbody_elem={
                            dataExchanges.ressuc.list.map((exchangeItem, _) => {
                              return [
                                <div
                                  className="InFlx Stclmnf CrsPoi"
                                  onClick={() =>
                                    history.push(
                                      "/order/detail/" + exchangeItem.display_id + "?source=order"
                                    )
                                  }
                                >
                                  {exchangeItem.display_id}
                                </div>,
                                <div
                                  onClick={() =>
                                    Event(
                                      "EXCHANGES",
                                      "CLICK_IN_STATUS_EXCHANGE_LIST_" + exchangeItem.status,
                                      "CLICK_EVENT"
                                    )
                                  }
                                >
                                  {/* this needs to be checked agin */}
                                  <StatusElementTootlip
                                    data_btn={[
                                      {
                                        action: () =>
                                          history.push(
                                            "/order/detail/" + exchangeItem.display_id + "?source=order"
                                          ),
                                        svgbtn:
                                        exchangeItem.status === 8
                                            ? "p" + exchangeItem.status
                                            : exchangeItem.status,
                                      }
                                    ]}
                                    status={
                                      exchangeItem.status === 8
                                        ? "p" + exchangeItem.status
                                        : exchangeItem.status
                                    }
                                  />
                                </div>,
                                <div
                                  className="InFlx Stclmnf CrsPoi"
                                  onClick={() =>
                                    history.push(
                                      "/order/detail/" + exchangeItem.display_id + "?source=order"
                                    )
                                  }
                                >
                                  {exchangeItem.commune + " ( " + exchangeItem.city + " ) "}
                                </div>,
                                <div
                                  className="InFlx Stclmnf CrsPoi"
                                  onClick={() =>
                                    history.push(
                                      "/order/detail/" + exchangeItem.display_id + "?source=order"
                                    )
                                  }
                                >
                                  {exchangeItem.creation_date}
                                </div>,
                                <GroupeBtn data_btn={[
                                  {
                                    type: "BDgInpc",
                                    action: () =>
                                    GenerateExchangesBordureauStarter([exchangeItem.display_id], false),
                                    svgbtn: PrintSvg,
                                    tooltip: Translate("tooltips", "newformatpdf"),
                                  }
                                ]} />
                              ]
                            })
                          }
                          stickySecnd={true}
                        />

                        <div role="pagination" className="lytWdp SmMarS1">
                          <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                            <span className="d-large">
                              {Translate("orders", "showing") +
                                " " +
                                (query.page ?? 1 * 20 - 19) +
                                " - " +
                                (query.page ?? 1 * 20 < dataExchanges.ressuc.count ?? 0
                                  ? query.page ?? 1 * 20
                                  : dataExchanges.ressuc.count ?? 0) +
                                " " +
                                Translate("orders", "of") +
                                " " +
                                dataExchanges.ressuc.count ?? 0}
                            </span>
                            <PaginationBar
                              className="StAutMr"
                              NbPage={Math.trunc(dataExchanges.ressuc.count ?? 0 / 20) + 1}
                              currentPage={query.page ?? 1}
                              ChangePage={() => {}}
                              blockNext={query.page ?? 0 + 1}
                              blockPrev={query.page ?? 0 - 1}
                            />
                          </div>
                        </div>
                      </div>
                }
              </div>
            :
              <div>
                error loading exchanges!!!
              </div>
          }
        </>
      }

      {
        (!dataProfile.is_store || !dataProfile.view_order)
        &&
        <FeatureLoked />
      }

    </ContainerPage>
  );
}
 
function mapState(state: any) {
  const { dataExchanges, GettingExchanges } = state.exchanges;
  const { dataProfile } = state.user;
  return { dataExchanges, GettingExchanges, dataProfile }
};

const actionCreators = {
  GetExchanges: exchangeActions.GetExchanges,
  SendAlert: alertActions.SendAlert,
  GenerateExchangesBordureauStarter: exchangeActions.GenerateExchangesBordureauStarter
}

const connctedExchangesPage = connect(mapState, actionCreators)(Exchanges);
export { connctedExchangesPage as Exchanges };