import React from "react";

interface IconButtonProps {
  clickHandler: () => void,
  icon: JSX.Element,
  label: string
}

const IconButton = ({
  clickHandler,
  icon,
  label
}: IconButtonProps) => {
  return (
    <button
      onClick={clickHandler}
      className="InFlx AlgnItm sb4R ClSidTh borderClr CrsPoi"
      style={{
        padding: "4px 10px",
        gap: "10px"
      }}
    >
      <span style={{ marginTop: "5px" }}> {icon} </span>
      <span 
        className="StpdVlrglt Fntcl" 
        style={{ fontSize: "16px", fontFamily: "Nunito Sans" }}
      > 
        {label} 
      </span>
    </button>
  );
}
 
export default IconButton;